<template>
  <div class="animated fadeIn">
    <b-form>
      <b-form-group
        v-if="form.phone"
        id="phoneOneInputGroupMain"
        label-cols-md="3"
        label="Телефон"
        label-for="shared-data-phone-one"
      >
        {{ form.phone }}
      </b-form-group>
      <b-form-group
        v-if="form.phoneOne"
        id="phoneOneInputGroup19"
        label-cols-md="3"
        label="Дополнительный телефон 1"
        label-for="shared-data-phone-one"
      >
        {{ form.phoneOne }}
      </b-form-group>
      <b-form-group
        v-if="form.phoneTwo"
        id="phoneTwoInputGroup20"
        label-cols-md="3"
        label="Дополнительный телефон 2"
        label-for="shared-data-phone-two"
      >
        {{ form.phoneTwo }}
      </b-form-group>
      <b-form-group
        v-if="form.fax"
        id="faxInputGroup21"
        label-cols-md="3"
        label="Факс"
        label-for="shared-data-fax"
      >
        {{ form.fax }}
      </b-form-group>
      <b-form-group
        v-if="form.email"
        id="emailInputGroup22"
        label-cols-md="3"
        label="Email"
        label-for="shared-data-email"
      >
        {{ form.email }}
      </b-form-group>
      <b-form-group
        v-if="form.notificationEmail"
        id="emailInputGroup223"
        label-cols-md="3"
        label="Email для уведомлений"
        label-for="shared-data-notification-email"
      >
        {{ form.notificationEmail }}
      </b-form-group>
      <b-form-group
        v-if="form.ownFleet"
        id="ownFleetInputGroup23"
        label-cols-md="3"
        label="Собственный автопарк"
        label-for="shared-data-own-autopark"
      >
        {{ form.ownFleet }}
      </b-form-group>
      <b-form-group
        v-if="form.attractedFleet"
        id="attractedFleetInputGroup24"
        label-cols-md="3"
        label="Привлеченный автопарк"
        label-for="shared-data-the-involved-autopark"
      >
        {{ form.attractedFleet }}
      </b-form-group>
      <b-form-group
        v-if="form.info"
        id="infoInputGroup25"
        label-cols-md="3"
        label="Дополнительная информация"
        label-for="shared-data-additional-information"
      >
        {{ form.info }}
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {customersContractorRead} from '../../services/api';
import {queryToUrl} from '../../services/http';
export default {
  name: 'ContractorSharedData',
  mixins: [notifications],
  props: ['contractorId'],
  data() {
    return {
      form: {},
      feedBack: 'secondary',
      loading: false,
    };
  },
  mounted() {
    this.customersContractorRead();
  },
  methods: {
    async customersContractorRead() {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        this.form = response.data.contractor;
        if (response.data.contractor.phones[0]) {
          this.form.phoneOne = response.data.contractor.phones[0].number;
        }
        if (response.data.contractor.phones[1]) {
          this.form.phoneTwo = response.data.contractor.phones[1].number;
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

